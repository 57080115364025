import { firestore } from "./Firebase"

//Get footer data
const footer = firestore.collection("footer");
const articles = firestore.collection("articles")
const quotes = firestore.collection("quotes")

class FirestoreDataService {
  getfooter() {
    return footer;
  }
  getarticles() {
    return articles.orderBy("postDate", "desc");
  }
  getquotes() {
    return quotes;
  }
}

export default new FirestoreDataService();