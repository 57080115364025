import React from"react"
import { Helmet } from "react-helmet"

const NotFoundPage = () =>(
    <>
        <Helmet>
            <title>Page Not Found | Usman Ahmad Saeed | UAS</title>
        </Helmet>
        <h1>
            404 - Not Found
        </h1>
    </>
)

export default NotFoundPage