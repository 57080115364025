import React from "react"
import { Link } from "react-router-dom"
import { animateScroll } from 'react-scroll';

const ArticlesList = ({articles}) => {
    return(
        <>
            {articles.map( (article, key) => (
                <Link className="nolinkdecor" title={article.name} key={key} to={`/article/${article.name}`} onClick={animateScroll.scrollToTop()}>
                    <figure className="effect" id={article.color} >
                        <img src={article.imgSrc} alt={article.imgAlt} />
                        <figcaption psuedoattr={article.name}>
                            <p className="oview">{article.content[0].substring(0, 40)}...</p>	
                        </figcaption>
                    </figure>
                </Link>
            ))}
        </>
    )
}

export default ArticlesList