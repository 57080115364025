import React from "react"
import SubmitForm from "../components/SubmitForm"
import { Helmet } from "react-helmet"

const ContactPage = () => (
    <>
        <Helmet>
            <title>Contact | Usman Ahmad Saeed | UAS</title>
        </Helmet>
        <p className="alert">Thank you. Your message has been sent successfully.</p>
        <form name="contactform" id="contactForm" method="post" encType="text/plain">
            <div className="inputs">
                <p className="inputh" title="Name">Name</p>
                    <input title="Please enter your full name." type="text" name="Name" id="name" maxLength="30" placeholder="Kevin" required />
                <p className="inputh" title="E-Mail">E-Mail</p>
                    <input title="Please enter your e-mail address." type="email" name="Email" id="email" maxLength="40" placeholder="Example@mail.com" required />
                <p className="inputh" title="Phone#">Phone #</p>
                    <input title="Please enter your phone number(with country code)." type="tel" name="Phone" id="phone" pattern="\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$" maxLength="16" placeholder="(+92)-300-1234567" required />
                <p className="inputh" title="Message">Message</p>
                    <textarea title="Please enter your message." rows="6" name="Message" id="message" maxLength="500" placeholder="Enter your query here." required></textarea>
            </div>
            <button title="Submit" id="submit" type="submit" name="submit">Submit</button>
        </form>
        <SubmitForm />
    </>
)

export default ContactPage