import { database } from "./Firebase"

const SubmitForm = () => {
      // Reference messages collection
      const messagesRef = database.ref('messages');

      // Listen for form submit
      window.onload = () => { document.getElementById('contactForm').addEventListener('submit', submitForm); }

      // Submit form
      function submitForm(e){
        e.preventDefault();

        // Get values
        var name = getInputVal('name');
        var email = getInputVal('email');
        var phone = getInputVal('phone');
        var message = getInputVal('message');
        
        
        // Save message
        saveMessage(name, email, phone, message);
        
        // Show alert
        document.querySelector('.alert').style.display = 'block';

        // Hide alert after 5 seconds
        setTimeout(function(){
          document.querySelector('.alert').style.display = 'none';
        },5000);

        // Clear form
        document.getElementById('contactForm').reset();
        
      }

      // Function to get get form values
      function getInputVal(id){
        return document.getElementById(id).value;
      }

      // Save message to firebase
      function saveMessage(name, email, phone, message){
        var newMessageRef = messagesRef.push();
        newMessageRef.set({
          name: name,
          email:email,
          phone:phone,
          message:message
        });
      }
      return null;
    }
export default SubmitForm