import React, { useState , useEffect } from "react"
import { Helmet } from "react-helmet"
import ArticlesList from "../components/ArticlesList"
import NotFoundPage from "./NotFoundPage"
import CommentsList from "../components/CommentsList"
import UpvotesSection from "../components/UpvotesSection"
import AddCommentForm from "../components/AddCommentForm"
import FirestoreDataService from "../components/FirestoreDataService"

const ArticlePage = ({ match }) => {
    const name = match.params.name;
    const [article, setArticle] = useState({
        name:"",
        color:"",
        imgAlt:"",
        imgSrc:"",
        postDate:"",
        content:[],
        upvotes:0,
        comments:[],
    })
    const [otherArticles, setOtherArticles] = useState({
        name:"",
        color:"",
        imgAlt:"",
        imgSrc:"",
        postDate:"",
        content:[],
        upvotes:0,
        comments:[],
    })
    const fetchData = async () => {
        try{
            FirestoreDataService.getarticles().onSnapshot( (articles) => {
            let articleslist = [];    
            articles.forEach((article) => {
              let data = article.data();
              articleslist.push({
                name: data.name,
                color: data.color,
                imgAlt: data.imgAlt,
                imgSrc: data.imgSrc,
                content: data.content,
                postDate: data.postDate,
                upvotes: data.upvotes,
                comments: data.comments,
              });
            });
            const article = articleslist.find(article => article.name === name)
            const otherArticles= articleslist.filter(article => article.name !== name)
            setArticle(article);
            setOtherArticles(otherArticles)
        });}
        catch (err) {
            console.error(err.message);
          }
    }
    useEffect(()=>{
        fetchData();
    }, [name])
    
    if(!article){
        return <NotFoundPage />
    }

    return(
        <>
            <Helmet>
                <title>{name + " | Blog | Usman Ahmad Saeed | UAS"}</title>
            </Helmet>
            <h1>{article.name}</h1>
            {article.content.map((paragraph, key)=>(
                <p key={key}>{paragraph}</p>
            ))}
            {/* <UpvotesSection articleName={name} upvotes={article.upvotes} setArticle={setArticle} />
            <CommentsList comments={article.comments} />
            <AddCommentForm articleName={name} setArticle={setArticle} /> */}
            <h2>
                Other Articles
            </h2>
            <ArticlesList articles={Array.from(otherArticles)} />
        </>
    );
}

export default ArticlePage