import React from 'react';
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom"

import NavBar from "./NavBar"
import HomePage from "./pages/HomePage"
import ContactPage from "./pages/ContactPage"
import Blog from "./pages/Blog"
import ArticlePage from "./pages/ArticlePage"
import NotFoundPage from "./pages/NotFoundPage"

import Cover from "./components/Cover"
import Loader from "./components/Loader"
import Footer from "./pages/Footer"

import 'jquery'
import 'bootstrap/dist/js/bootstrap.min';
import './stylesheets/bootstrap.min.css'
import './stylesheets/fontawesome-all.min.css'
import './stylesheets/style.css';
import './stylesheets/blog.css';
import './stylesheets/contact.css';
import './stylesheets/mediaqueries.css';

function App() {
  function pageload(){
    setTimeout(showPage,2000)
  }
  function showPage(){
    document.getElementById("loader").style.display="none"
    document.getElementById("PageContent").style.display="block"
  }
  return (
    <Router>
      <Loader pageload={pageload()}/>
      <div className="App animate-bottom" id="PageContent">
      <Cover />
      <NavBar />
      <div id="content">
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/contact" component={ContactPage} />
        <Route path="/blog" component={Blog} />
        <Route path="/article/:name" component={ArticlePage} />
        <Route component={NotFoundPage} />
      </Switch>
      </div>
      <Footer />
    </div>
    </Router>
  );
}

export default App;