import React, { useState, useEffect } from "react";
import ArticlesList from "../components/ArticlesList"
import FirestoreDataService from "../components/FirestoreDataService"
import { Helmet } from 'react-helmet'

const Blog = () => {
    const [articles, setArticles] = useState({
        name:"",
        color:"",
        imgAlt:"",
        imgSrc:"",
        postDate:"",
        content:[],
        upvotes:0,
        comments:[],
    })
    const fetchData = async () => {
        try{
            FirestoreDataService.getarticles().onSnapshot( (articles) => {
            let articleslist = [];    
            articles.forEach((article) => {
              let data = article.data();
              articleslist.push({
                name: data.name,
                color: data.color,
                imgAlt: data.imgAlt,
                imgSrc: data.imgSrc,
                content: data.content,
                postDate: data.postDate,
                upvotes: data.upvotes,
                comments: data.comments,
              });
            });
            setArticles(articleslist)
        });}
        catch (err) {
            console.error(err.message);
          }
    }
    useEffect(()=>{
        fetchData();
    },[])
    return(
          <>
            <Helmet>
                <title>Blog | Usman Ahmad Saeed | UAS</title>
            </Helmet>
              <h1>Articles</h1>
              <ArticlesList articles={Array.from(articles)} />
          </>
      )
    }

export default Blog