import React, { useState, useEffect, useRef } from "react";

const NavBar = () => {
    const currentPage = window.location.pathname
    const dropdown = useRef()
    const [pages, setPages] = useState({
        path:"",
        name:"",
    })
    const [page, setPage] = useState({
        path:"",
        name:"",
    })
  useEffect(()=>{
    let pages= [{
            path: "/",
            name: "Profile",
        },{
            path: "/contact",
            name: "Contact",
        },{
            path: "/blog",
            name: "Blog",
        }]
        const otherPages = pages.filter(page => page.path !== currentPage)
        setPages(otherPages)
        if (pages.some(page => page.path === currentPage)){
            const page = pages.find(page => page.path === currentPage)
            setPage(page)
        }else{
            const page = pages.find(page => page.path === "/blog")
            setPage(page)
        }
    },[currentPage])
    function drop(){
        dropdown.current.classList.contains("showMenu") ? dropdown.current.classList.remove("showMenu") : dropdown.current.classList.add("showMenu")
    }
    const pageList = Array.from(pages)
    return(
        <nav id="middlenav" className="midnav">
            <p className="midp" title={`${page.name}`} onClick={drop}>{page.name}</p>
            <div className="dropdown" id="dropdown" ref={dropdown}>
                {pageList.map((page, key) => ( 
                    <a href={`${page.path}`} key={key} className="nolinkdecor" title={`${page.name}`}>
                        { `${page.name}` }
                    </a>
                ))}
            </div>
        </nav>
    )
}

export default NavBar;