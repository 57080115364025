import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import FirestoreDataService from "../components/FirestoreDataService"

const Footer = () => {
  const [links, setLinks] = useState({
    name:"",
    color:"",
    imgAlt:"",
    imgSrc:"",
    postDate:"",
    content:[],
    upvotes:0,
    comments:[],
  })
const fetchData = async () => {
    try{
        FirestoreDataService.getfooter().onSnapshot( (items) => {
          let linkslist = [];
          items.forEach((item) => {
            let data = item.data();
            linkslist.push({
              id: data.id,
              title: data.title,
              faClassName: data.faClassName,
              url: data.url,
              imgClass: data.imgClass,
              imgAlt: data.imgAlt,
              imgSrc: data.imgSrc,
              color: data.color
            });
        });
        setLinks(linkslist)
      });
    }
    catch (err) {
      console.error(err.message);
    }
  }
  useEffect(()=>{
      fetchData();
  },[]) 
  const data = Array.from(links);
  function imgOrIcon (doc){
    if (doc.faClassName){
      return (<i className={`${doc.faClassName}`} style={{color:`${doc.color}`}}></i>)
    }
    else{
      return (<img className={`${doc.imgClass}`} alt={`${doc.imgAlt}`} src={`${doc.imgSrc}`} />)
    }
   }
  return(
    <footer className="bottomnav">
      {data.map((doc, key) => ( 
        <>
          <Link rel="noopener noreferrer" key={key} className="nolinkdecor" id={`${doc.id}`} title={`${doc.title}`} target="_blank" to={{ pathname: `${doc.url}` }}>
            { imgOrIcon(doc) }
          </Link>
          {/* &emsp; */}
        </>
      ))}
    </footer>
  )
}

export default Footer