import React from "react"
import { Helmet } from "react-helmet"

const HomePage = () => (
    <>
        <Helmet>
            <title>Usman Ahmad Saeed | UAS</title>
        </Helmet>
        <p id="introtxt">
                usmanahmadsaeed@UAS ~
            <br />
            <br />
                $ Hello to the World!
            <br />
                $ From Usman Ahmad Saeed
            <br />
                $ Involved in IT & STEM
            <br />
                $ Passionately curious
            <br />
                $ This is ME
            <br />
            <span className="activeLine" id="introtxt">Remaining Universe Under Construction... <span className="blink">.</span></span>
        </p>
    </>
)

export default HomePage